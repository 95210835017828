<template>
  <div class="about">
    <img src="https://ccc.xnrun.com/Wisdom_life/upfile/1606383759.png" alt="">
    <div class="section1">
      <!-- <h1>企业概述</h1> -->
      <div class="inner">

      </div>
    </div>
    <div class="section2"></div>
    <div class="section3"></div>
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style lang="scss" scoped="">
  .about{
    // font-size: 0;
    img{
      width: 100%;
      display: block;
    }
    .section1{
    }
  }
</style>
